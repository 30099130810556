import landingActions from "./store/landing-actions";
import landingState from "./store/landing-state";
import landingMutations from "./store/landing-mutations";
import landingGetters from "./store/landing-getters";

/* This is the syntax for creating a Vuex module of authentication proccess. */
const landing = {
  namespaced: true,
  state: landingState,
  actions: landingActions,
  mutations: landingMutations,
  getters: landingGetters,
};

export { landing };

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  showLoading({ commit }) {
    commit("LOADING_PROCESS");
  },

  /* It calls the logout method of the AuthService */
  hideLoading({ commit }) {
    commit("LOADING_FINISH");
  },
};

import { createRouter, createWebHistory } from "vue-router";

import LandingRoutes from "../modules/scheduler-landing/index";

const list = [...LandingRoutes];
let routes = [];

list.forEach((items) => {
  if (Array.isArray(items)) {
    items.forEach((item) => {
      routes.push(item);
    });
  } else {
    routes.push(items);
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

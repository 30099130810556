/* Creating a class called `LocalStorageService` that has a constructor that takes no arguments. */
class LocalStorageService {
  /**
   * Get the data from local storage
   * @returns The token is being returned.
   */
  getData(item) {
    return JSON.parse(localStorage.getItem(item));
  }
  /**
   * Set the data in local storage
   * @param authData - The authentication data returned from the server.
   */
  setData(item, data) {
    localStorage.setItem(item, JSON.stringify(data));
  }
  /**
   * Remove the data from local storage
   */
  removeData(item) {
    localStorage.removeItem(item);
  }
  /**
   * clear the data from local storage
   */
  clearData() {
    localStorage.clear();
  }
}
export default new LocalStorageService();

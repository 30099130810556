import AuthService from "@/services/auth-service";
import LocalStorageService from "@/services/local-storage-service";
import MultiSchedulerService from "@/services/multischeduler-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * It calls the login method of the AuthService and returns a Promise.
   * @param model - The login model that is being passed to the login function.
   * @returns The promise is returned.
   */
  async login({ commit }, model) {
    return await AuthService.login(model)
      .then(async (userToken) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (userToken.token) {
          commit("LOGIN_SUCCESS", { token: userToken });
        }

        return Promise.resolve(userToken);
      })
      .catch((error) => {
        commit("LOGIN_FAILURE");
        return Promise.reject(error);
      });
  },

  /**
   * It calls the login method of the AuthService and returns a Promise.
   * @param model - The login model that is being passed to the login function.
   * @returns The promise is returned.
   */
  async getAvailabilityClients({ commit }, model) {
    return await MultiSchedulerService.get(model)
      .then(async (response) => {
        var clients = response.data;
        //LocalStorageService.setData("user-data", user);
        commit("CLIENTS_SUCCESS", clients);
        return Promise.resolve(clients);
      })
      .catch((error) => {
        commit("CLIENTS_FAILURE");
        return Promise.reject(error);
      });
  },

  /* It calls the logout method of the AuthService */
  logout({ commit }) {
    AuthService.logout();
    LocalStorageService.clearData();
    commit("LOGOUT");
  },
  /* This is a mutation by refresh token. */
  refreshToken({ commit }, token) {
    commit("REFRESH_TOKEN", token);
  },
};

import api from "./security-api-service";
import TokenService from "./token-service";

/* A service that provides a way to interact with the Owin Authentication API. */
class AuthService {
  /**
   * It sends a POST request to the /token endpoint with the model as the body.
   * @param model - The model that will be sent to the server.
   * @returns The access token.
   */
  async login(model) {
    return await api
      .post("/login", model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.token) {
          TokenService.setToken(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }
  /**
   * Logs out the user by removing the token from the local storage
   */
  logout() {
    TokenService.removeToken();
  }
}
export default new AuthService();

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import insurerAuthInterceptor from "./services/insurer-auth-interceptor";
import cityAuthInterceptor from "./services/city-auth-interceptor";
import planAuthInterceptor from "./services/plan-auth-interceptor";
import servicesAuthInterceptor from "./services/services-auth-interceptor";
import multiSchedulerApiInterceptor from "./services/multi-scheduler-api-interceptor";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import ddTrack from "./plugins/dd-tracking";

insurerAuthInterceptor.insurer(store);
cityAuthInterceptor.city(store);
planAuthInterceptor.plan(store);
servicesAuthInterceptor.service(store);
multiSchedulerApiInterceptor.multischeduler(store);

const app = createApp(App)
  .use(ddTrack, router)
  .use(store)
  .use(Antd)
  .use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.use(router);
app.mount("#app");

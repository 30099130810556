export default {
  CITIES_SUCCESS(state, cities) {
    state.Cities = cities;
  },

  CITIES_FAILURE(state) {
    state.Cities = null;
  },
  CITIES_CLEAN(state) {
    state.Cities = null;
  },
  SERVICES_SUCCESS(state, services) {
    state.Services = services;
  },
  SERVICES_FAILURE(state) {
    state.Services = null;
  },
  SERVICES_CLEAN(state) {
    state.Services = null;
  },
  INSURANCES_SUCCESS(state, insurances) {
    state.Insurances = insurances;
  },
  INSURANCES_FAILURE(state) {
    state.Insurances = null;
  },
  INSURANCES_CLEAN(state) {
    state.Insurances = null;
  },
  PLANS_SUCCESS(state, plans) {
    state.Plans = plans;
  },
  PLANS_FAILURE(state) {
    state.Plans = null;
  },
  PLANS_CLEAN(state) {
    state.Plans = null;
  },
  CLIENTS_SUCCESS(state, clients) {
    state.Clients = clients;
  },
  CLIENTS_FAILURE(state) {
    state.Clients = null;
  },
  cLIENTS_CLEAN(state) {
    state.Clients = null;
  },
  LOGOUT(state) {
    state.status.loggedIn = false;
    state.token = null;
    state.user = null;
  },
};

import axios from "axios";

/* This is creating an instance of axios. */
const instance = axios.create({
  baseURL: process.env.VUE_APP_INSURANCES_API,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
export default instance;

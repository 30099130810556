import insurerInstance from "./insurer-api-service";
import authService from "./auth-service";
import TokenService from "./token-service";

const user = process.env.VUE_APP_USER;
const password = process.env.VUE_APP_PASSWORD;

const insurer = (store) => {
  /* Token Interceptor for request. */
  insurerInstance.interceptors.request.use(
    async (config) => {
      const tokenData = TokenService.getToken();
      var theTime = null;
      if (tokenData) {
        theTime = new Date(tokenData.time);
      }
      var currentDate = new Date();
      if (tokenData && currentDate.getDate() == theTime.getDate()) {
        config.headers["Authorization"] = "Bearer " + tokenData.token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      } else {
        let model = { username: user, password: password };
        await authService
          .login(model)
          .then(async (data) => {
            config.headers["Authorization"] = "Bearer " + data.token;
          })
          .catch((error) => {
            store.dispatch("landing/logout");
            return Promise.reject(error);
          });
      }
      return config;
    },
    (error) => {
      store.dispatch("landing/logout");
      return Promise.reject(error);
    }
  );
  insurerInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/token" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            let model = { username: user, password: password };
            const rs = await authService
              .login(model)
              .then((result) => {
                return result;
              })
              .catch((error) => {
                store.dispatch("auth/logout");
                return Promise.reject(error);
              });

            if (rs.status == 200) {
              const { access_token } = rs.data.token;
              TokenService.updateLocalAccessToken(access_token);
            }
            if (rs.status == 400) {
              store.dispatch("auth/logout");
            }

            return insurerInstance(originalConfig);
          } catch (_error) {
            store.dispatch("auth/logout");
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default { insurer };

import pageActions from "./page-actions";
import pageState from "./page-state";
import pageMutations from "./page-mutations";

/* This is the syntax for creating a Vuex module. */
const page = {
  namespaced: true,
  state: pageState,
  actions: pageActions,
  mutations: pageMutations,
};

export { page };

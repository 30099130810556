const Landing = () => import("../view/landing.vue");
const SchedulerSelect = () => import("../view/scheduler-select.vue");
const External = () => import("../components/external.vue");

export default [
  {
    name: "landing",
    path: "/",
    meta: {
      Title: "Multiagendador",
      layout: "layout-landing",
    },
    component: Landing,
  },
  {
    name: "scheduler-select",
    path: "/scheduler-select",
    meta: {
      Title: "Agendadores",
      layout: "layout-landing",
    },
    component: SchedulerSelect,
    beforeEnter(to, from, next) {
      if (from.name == "landing") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    name: "external",
    path: "/linkredirect",
    meta: {
      Title: "Accion Externa",
      layout: "layout-landing",
    },
    component: External,
  },
];

import { createStore } from "vuex";
import { page } from "./page-module";
import { landing } from "../modules/scheduler-landing/landing-module";

/* It creates a store object with the modules, getters, state, mutations, and actions. */
const store = createStore({
  modules: {
    page,
    landing,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
export default store;

import api from "./multischeduler-api-service";

/* It's a class that has a method called get that returns a promise. */
class MultischedulerService {
  get(filter) {
    return api.get("/Homologation/List/", {
      params: filter,
    });
  }
}
export default new MultischedulerService();

<template>
  <a-spin :spinning="spinning" tip="Cargando..." size="large">
    <router-view />
    <!-- <footer>
      <p>
        Creado por
        <img src="@/assets/img/cropped-Logo-blanco.png" alt="dondoctor" />
      </p>
    </footer> -->
  </a-spin>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const pageObj = computed(() => store.state.page);

    let spinning = computed(() => {
      return pageObj.value.loading;
    });
    return { spinning };
  },
};
</script>
<style lang="scss">
@import "@/assets/style/index.scss";
</style>
<style>
@import "@/assets/style/ant-notification.css";
</style>

export default {
  install: async (app, router) => {
    const loadScript = () => {
      return new Promise((resolve) => {
        const trackScript = document.createElement("script");
        trackScript.id = "dd_track";
        trackScript.src = `${process.env.VUE_APP_TRACK_API}scripts/tracking.js?o=Multiagendador&v=1.0.2`;
        trackScript.onload = async () => {
          if (window._ddtrack) {
            resolve(window._ddtrack);
            await window._ddtrack.track(
              localStorage[window._ddtrack.trackName],
              (track) => {
                localStorage.setItem(window._ddtrack.trackName, track);
              }
            );

            window._ddtrack.setClearCall(() => {
              localStorage.removeItem(window._ddtrack.trackName);
            });

            router.afterEach(() => {
              if (window._ddtrack) {
                window._ddtrack.pageView(
                  localStorage[window._ddtrack.trackName]
                );
              }
            });
          }
        };

        window.document.head.append(trackScript);
      });
    };

    const tracker = await loadScript();

    app.provide("ddTrack", tracker);
  },
};
